












































































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PomodoroTimer extends Vue {
  totalTime: number = (25 * 60);
  timerRunning: boolean = false;
  timerPaused: boolean = false;
  interval: any | null = null;
  isModalActive: boolean = false;

  @Prop({required: false, default: false})
  inModal!: boolean;

  get time() {
    return this.minutes + ":" + this.seconds;
  }
  get minutes() {
    let min = Math.floor(this.totalTime / 60);
    return min >= 10 ? min : '0' + min;
  }
  get seconds() {
    //@ts-ignore
    let sec = this.totalTime - (this.minutes * 60);
    return sec >= 10 ? sec : '0' + sec;
  }

  timerRun() {
    this.$gtag.event('pmb_user_started_pomodoro');
    this.timerRunning = true;
    this.interval = setInterval(this.countdownTimer, 1000);
   
  }
  timerPause() {
    this.timerRunning = false;
    clearInterval(this.interval);
  }
  timerReset() {
    this.timerRunning = false;
    clearInterval(this.interval);
    this.totalTime = (25 * 60);
    this.interval = null;
    this.timerRunning = false;
    this.timerPaused = false;
  }
  playSound() {
    let audio = new Audio(require('@/assets/audio/pomodoro-done-sound.mp3'));
    audio.volume = 0.1;
    audio.play();
    
  }
  timerCountdown() {
 
    this.timerRunning = true;
    //@ts-ignore
    this.interval = setInterval(this.updateCurrentTime, 1000);
    // Counts down from 60 seconds times 1000.
    setInterval( () => {
      //@ts-ignore
      this.timerMinutes--
    }, 60 * 1000)

    // Check if seconds at double zero and then make it a 59 to countdown from.
    // need another method of checking the number while in the loop and then adding a zero on the number under 10
    //@ts-ignore
    if(this.timerSeconds === '00'){
      //@ts-ignore
      this.timerSeconds = 59;
      setInterval( () => {
        //@ts-ignore
        this.timerSeconds--
      }, 1000);
    } else {
      setInterval( () => {
        //@ts-ignore
        this.timerSeconds--
      }, 1000);
    }
  }
  countdownTimer() {
    if (this.timerRunning === true) {
      this.totalTime--;

      if(this.totalTime === 2) {
        this.playSound();
      }

      if(this.totalTime === 0) {
        this.timerReset();
      }
    }
  }
}
